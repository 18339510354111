var _excluded = ["color", "size", "strokeWidth", "children"];
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import { forwardRef, createElement } from 'react';
import PropTypes from 'prop-types';
import defaultAttributes from './defaultAttributes';

/**
 * Converts string to KebabCase
 * Copied from scripts/helper. If anyone knows how to properly import it here
 * then please fix it.
 *
 * @param {string} string
 * @returns {string} A kebabized string
 */
export var toKebabCase = function toKebabCase(string) {
  return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
};
export default (function (iconName, iconNode) {
  var Component = forwardRef(function (_ref, ref) {
    var _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'currentColor' : _ref$color,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? 24 : _ref$size,
      _ref$strokeWidth = _ref.strokeWidth,
      strokeWidth = _ref$strokeWidth === void 0 ? 2 : _ref$strokeWidth,
      children = _ref.children,
      rest = _objectWithoutPropertiesLoose(_ref, _excluded);
    return createElement('svg', _extends({
      ref: ref
    }, defaultAttributes, {
      width: size,
      height: size,
      stroke: color,
      strokeWidth: strokeWidth,
      className: "lucide lucide-" + toKebabCase(iconName)
    }, rest), [].concat(iconNode.map(function (_ref2) {
      var tag = _ref2[0],
        attrs = _ref2[1];
      return createElement(tag, attrs);
    }), children || []));
  });
  Component.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };
  Component.displayName = "" + iconName;
  return Component;
});