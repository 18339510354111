import createReactComponent from '../createReactComponent';
var Linkedin = createReactComponent('Linkedin', [['path', {
  d: 'M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z',
  key: 'c2jq9f'
}], ['rect', {
  x: '2',
  y: '9',
  width: '4',
  height: '12',
  key: 'fu1a4h'
}], ['circle', {
  cx: '4',
  cy: '4',
  r: '2',
  key: 'bt5ra8'
}]]);
export default Linkedin;